import { React } from 'react'
import { s, sInput } from '../style'
import Button from './Button'

const SearchBar = ({ searchQuery, setSearchQuery, actionInput, actionButton }) => {

  return (
    <form action='/wyszukaj/' method='get' autoComplete='off' css={sSearchForm}>
      <input
        value={searchQuery}
        onInput={(e) => setSearchQuery(e.target.value)}
        type='text'
        id='header-search'
        placeholder=''
        name='s'
        css={[sInput, {marginBottom: '0 !important'}]}
        onFocus={actionInput}
        onClick={actionInput}
        // autoFocus
        aria-label="Wyszukaj"
      />
      <Button glow action={actionButton} searchSubmit>
        Wyszukaj
      </Button>
    </form>
  );
};

const sSearchForm = {
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  marginTop: '2rem',
  [s.xs]: {
    flexDirection: 'column',
    gap: '10px',
    marginTop: '1.25rem',
  }
}

export default SearchBar
